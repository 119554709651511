import React from 'react';

function Chinatown() {
    return (
        <div className="artPiece">
                <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/chinatownNYC.jpg" alt="Chinatown, NYC" />
                <div className="title">
                    <span>Chinatown, NYC</span>
                </div>

        </div >
    );
}

export default Chinatown;
