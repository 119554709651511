import React from 'react';

function HotPotCover() {
    return (
        <div className="artPiece">
                <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/hotPotOrChowder.jpg" alt="Hot Pot or Chowder?" />
                <div className="title">
                    <span>Hot Pot or Chowder?</span>
                </div>
        </div >
    );
}

export default HotPotCover;

