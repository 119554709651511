import React from 'react';

function SnackCover() {
    return (
        <div className="artPiece">
              <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/snack.jpg" alt="Snack?" />
              <div className="title">
                <span>Snack?</span>
              </div>
        </div >
    );
}

export default SnackCover;

