import React from 'react';

function festivitiesCover() {
    return (
        <div className="artPiece">
              <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/festivities.jpg" alt="Festivities" />
              <div className="title">
                <span>Festivities</span>
              </div>
        </div >
    );
}

export default festivitiesCover;

