import React from 'react';

function gatewayCover() {
    return (
        <div className="artPiece">
                <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/gateway.jpg" alt="Gateway" />
                <div className="title">
                    <span>Gateway</span>
                </div>
        </div >
    );
}

export default gatewayCover;

