import React from 'react';

function TeaTimeCover() {
    return (
        <div className="artPiece">
                <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/teaTime.jpg" alt="Tea Time" />
                <div className="title">
                    <span>Tea Time?</span>
                </div>
        </div >
    );
}

export default TeaTimeCover;

