import React from 'react';

function StreetFoodCover() {
    return (
        <div className="artPiece">
              <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/streetFood.jpg" alt="Street Food" />
              <div className="title">
                <span>Street Food</span>
              </div>
        </div >
    );
}

export default StreetFoodCover;

