import React from 'react';

function culturalImmersion() {
    return (
        <div className="artPiece">
              <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/culturalImmersion.jpg" alt="Cultural Immersion" />
              <div className="title">
                <span>Cultural Immersion</span>
              </div>
        </div >
    );
}

export default culturalImmersion;

