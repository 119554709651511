import React from 'react';

function lunchorbrunchCover() {
    return (
        <div className="artPiece">
                <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/brunchOrLunch.jpg" alt="Lunch or Brunch?" />
                <div className="title">
                    <span>Lunch or Brunch?</span>
                </div>
        </div >
    );
}

export default lunchorbrunchCover;

