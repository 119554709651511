import React from 'react';

function culturalReflectionCover() {
    return (
        <div className="artPiece">
                <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/culturalReflection.jpg" alt="Cultural Reflection" />
                <div className="title">
                    <span>Cultural Reflection</span>
                </div>
        </div >
    );
}

export default culturalReflectionCover;

