import React from 'react';

function TouringCover() {
    return (
        <div className="artPiece">
              <img src="fusionOfCulturesIndividual/fusionOfCulturesPhotos/touring.jpg" alt="Touring" />
              <div className="title">
                <span>Touring</span>
              </div>
        </div >
    );
}

export default TouringCover;

